<template>
    <div class="email_comp">
        <div v-if="isRecipient" class="form_grp">
            <div class="group_item">
                <label class="input_label">Email Recipients <a class="option" @click="emailAdd = true">Add Email</a></label>
                <div class="field_wpr">
                    <Multiselect v-model="recipients.value" v-bind="recipients" placeholder="Assigned Recipients.."></Multiselect>
                </div>
            </div>
        </div>
        <div v-if="emailAdd" class="edit_section">
            <div class="section_title">Add to Address Book</div>
            <div class="form_grp">
                <div class="group_item">
                    <label class="input_label">Name</label>
                    <div class="field_wpr has_prefix">
                        <input type="text" placeholder="John Doe" >
                        <span class="prefix"><i class="fas fa-user"></i></span>
                    </div>
                </div>
            </div>
            <div class="form_grp">
                <div class="group_item">
                    <label class="input_label">Email</label>
                    <div class="field_wpr has_prefix">
                        <input type="text" placeholder="example@gmail.com" >
                        <span class="prefix"><i class="fas fa-envelope"></i></span>
                    </div>
                </div>
            </div>
            <div class="action_wpr">
                <button class="btn cancel_btn" @click="emailAdd = false">Cancel</button>
                <button class="btn save_btn">Add</button>
            </div>
        </div>
        <div class="form_grp">
            <div class="group_item">
                <label class="input_label">
                    Subject Line
                    <emoji-picker :onSelectEmoji="setEmoji" classes="subject-emoji-picker ml-auto mr-2" />
                    <div class="dropdown" @click="emailOption = !emailOption">
                        <i class="fas fa-user-alt"></i>
                        <div class="drp_wrapper" v-if="emailOption" :class="{active : emailOption}">
                            <ul>
                                <li>Company Name</li>
                                <li>Owner First Name</li>
                                <li>Company Owner</li>
                                <li>Company Address</li>
                                <li>Company Website</li>
                                <li>Company Phone</li>
                            </ul>
                        </div>
                    </div>
                </label>
                <div class="field_wpr">
                    <input type="text" placeholder="Type Subject" v-model="subject">
                </div>
            </div>
        </div>
        <div class="form_grp">
            <div class="group_item">
                <label class="input_label">
                    Message
                    <div class="dropdown" @click="msgOption = !msgOption">
                        <i class="fas fa-ellipsis-h"></i>
                        <div class="drp_wrapper" v-if="msgOption" :class="{active : msgOption}">
                            <ul>
                                <li>Reset to default message</li>
                                <li @click="emailLibrary = !emailLibrary" :class="{active : emailLibrary}">Import Template</li>
                                <li @click="testEmail = !testEmail" :class="{active : testEmail}">Send test email</li>
                                <li>Preview in browser</li>
                                <li>Save as template</li>
                            </ul>
                        </div>
                    </div>
                </label>
                <div class="editorWpr">
                    <img src="@/assets/images/editor.png">
                </div>
                <span class="letter_count">0 / 160</span>
                <div v-if="emailLibrary">
                    <h3>Import Template</h3>
                    <div class="edit_section">
                        <div class="library_wpr">
                            <div class="card_item"><img src="@/assets/images/mail.svg">E-mail</div>
                            <div class="library_field">
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Category</label>
                                        <div class="field_wpr">
                                            <select v-model="templateType">
                                                <option disabled selected hidden>Select Category</option>
                                                <option value="Affiliate">Affilliate</option>
                                                <option value="Ascension">Ascension</option>
                                                <option value="Awareness">Awareness</option>
                                                <option value="Daily Email">Daily Email</option>
                                                <option value="Direct Pitch">Direct Pitch</option>
                                                <option value="Engagement">Engagement</option>
                                                <option value="Holiday">Holiday</option>
                                                <option value="Indirect Pitch">Indirect Pitch</option>
                                                <option value="Indoctrination">Indoctrination</option>
                                                <option value="One-Off">One-Off</option>
                                                <option value="Re-engagement">Re-engagement</option>
                                                <option value="Segmentation">Segmentation</option>
                                                <option value="Showcase">Showcase</option>
                                                <option value="Special Offer">Special Offer</option>
                                                <option value="Useful But Incomplete">Useful But Incomplete</option>
                                                <option value="Unassigned">Unassigned</option>
                                            </select>
                                            <span class="icon"><i class="fas fa-angle-down"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="templateType" class="form_grp">
                            <div class="group_item">
                                <div class="field_wpr">
                                    <input type="text" placeholder="Search">
                                </div>
                            </div>
                        </div>
                        <div class="action_wpr mt-4">
                            <button class="btn cancel_btn" @click="emailLibrary = false">Cancel</button>
                            <button class="btn save_btn">Import</button>
                        </div>
                    </div>
                </div>
                <div v-if="testEmail">
                    <h3>Send Test Email</h3>
                    <div class="edit_section">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Email Address</label>
                                <div class="field_wpr has_suffix">
                                    <input type="text" placeholder="john.doe@onboardme.io">
                                    <span class="suffix">Send</span>
                                </div>
                            </div>
                        </div>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Email Style</label>
                                <div class="field_wpr">
                                    <select>
                                        <option disabled selected hidden>Select Style</option>
                                        <option>Simple</option>
                                        <option>Corporate</option>
                                    </select>
                                    <span class="icon"><i class="fas fa-angle-down"></i></span>
                                </div>
                            </div>
                        </div>
                        <div class="action_wpr mt-4">
                            <button class="btn cancel_btn" @click="testEmail = false">Cancel</button>
                            <button class="btn save_btn">Send Preview</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <label for="logo" class="switch_option capsule_btn">
            <h5>Show Email Logo<span>Phasellus nec eros nec leo pharetra volutpat. Cras finibus enim sit amet elit ornare blandit.</span></h5>
            <input type="checkbox" v-model="showEmail" id="logo" hidden>
            <div><span></span></div>
        </label>
        <div v-if="showEmail" class="upload_image">
            <label for="show_email">                   
                <input type="file" @change="fileSelected" id="show_email" hidden>
                <img src="../assets/images/image2.png">
                <h4>Click to Upload</h4>
                <p>or drag and drop SVG, PNG, JPG or GIF</p>
            </label>
        </div>
        <label for="signature" class="switch_option capsule_btn">
            <h5>Show Email Signature<span>Donec vehicula, ex at molestie auctor, tortor eros commodo ex, vitae tempus enim diam non turpis.</span></h5>
            <input type="checkbox" v-model="showSignature" id="signature" hidden>
            <div><span></span></div>
        </label>
        <div v-if="showSignature" class="form_grp">
            <div class="group_item">
                <label class="input_label">Signature <a class="option" @click="createSignature = !createSignature">Create Signature</a></label>
                <div class="field_wpr">
                    <select>
                        <option disabled selected hidden>Select Signature</option>
                        <option>Name 1</option>
                        <option>Name 2</option>
                    </select>
                    <span class="icon"><i class="fas fa-angle-down"></i></span>
                </div>
            </div>
        </div>
        <div v-if="createSignature && showSignature">
            <div class="form_grp">
                <div class="group_item">
                    <label class="input_label">Signature Name (internal use only)</label>
                    <div class="field_wpr">
                        <input type="text" placeholder="Signature" >
                    </div>
                </div>
            </div>
            <label for="brand" class="switch_option capsule_btn">
                <h5>Brand Bar<span>Donec vehicula, ex at molestie auctor, tortor eros commodo ex, vitae tempus enim diam non turpis.</span></h5>
                <input type="checkbox" v-model="brandBar" id="brand" hidden>
                <div><span></span></div>
            </label>
            <div v-if="brandBar" class="edit_section">
                <div class="section_title mb-1">Brand Bar</div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Bar Color</label>
                        <div class="field_wpr">
                            <input type="text" placeholder="#2F7FED" >
                        </div>
                    </div>
                    <div class="group_item">
                        <label class="input_label">Bar Text Color</label>
                        <div class="field_wpr">
                            <input type="text" placeholder="#fff" >
                        </div>
                    </div>
                </div>
                <div class="form_grp col-3">
                    <div class="group_item">
                        <label class="input_label">Top Padding</label>
                        <div class="field_wpr">
                            <input type="number" value="10" >
                        </div>
                    </div>
                    <div class="group_item">
                        <label class="input_label">Bottom Padding</label>
                        <div class="field_wpr">
                            <input type="number" value="20" >
                        </div>
                    </div>
                    <div class="group_item">
                        <label class="input_label">Corner Radius</label>
                        <div class="field_wpr">
                            <input type="number" value="10" >
                        </div>
                    </div>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Full Name</label>
                        <div class="field_wpr">
                            <input type="text" placeholder="John Doe" >
                        </div>
                    </div>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Credential</label>
                        <div class="field_wpr">
                            <input type="text" placeholder="PHD, CSCS" >
                        </div>
                    </div>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Company Name</label>
                        <div class="field_wpr">
                            <input type="text" placeholder="Synergy Fitness" >
                        </div>
                    </div>
                </div>
            </div>
            <label for="bio" class="switch_option capsule_btn">
                <h5>Bio<span>Donec vehicula, ex at molestie auctor, tortor eros commodo ex, vitae tempus enim diam non turpis.</span></h5>
                <input type="checkbox" v-model="bio" id="bio" hidden>
                <div><span></span></div>
            </label>
            <div v-if="bio" class="edit_section">
                <div class="section_title mb-1">Brand Bio</div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Slogan</label>
                        <div class="field_wpr">
                            <input type="text" placeholder="Synergy Fitness" >
                        </div>
                    </div>
                </div>
                <div class="form_grp col-3">
                    <div class="group_item">
                        <label class="input_label">Author Image</label>
                        <div class="upload_image mb-1">
                            <label for="logo">                   
                                <input type="file" @change="fileSelected" id="logo" hidden>
                                <div class="select_img">
                                    <img src="../assets/images/avatar-mask.png">
                                </div>
                                <h4>Click to Upload</h4>
                                <p>or drag and drop SVG, PNG, JPG or GIF</p>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Phone</label>
                        <div class="field_wpr">
                            <input type="text" v-model="phone" >
                        </div>
                    </div>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Email</label>
                        <div class="field_wpr has_prefix">
                            <input type="text" placeholder="example@gmail.com" >
                            <span class="prefix"><i class="fas fa-envelope"></i></span>
                        </div>
                    </div>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Website</label>
                        <div class="field_wpr">
                            <input type="text" placeholder="https://yourwebsite.com" >
                        </div>
                    </div>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Address</label>
                        <div class="field_wpr">
                            <input type="text" placeholder="286 Whitehill Street" >
                        </div>
                    </div>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Link Color</label>
                        <div class="field_wpr">
                            <input type="text" placeholder="#2F7FED" >
                        </div>
                    </div>
                </div>
                <label for="abbreviate" class="switch_option capsule_btn">
                    <h5>Abbreviate<span>Donec vehicula, ex at molestie auctor, tortor eros commodo ex, vitae tempus enim diam non turpis.</span></h5>
                    <input type="checkbox" v-model="abbreviate" id="abbreviate" hidden>
                    <div><span></span></div>
                </label>
            </div>
            <label for="social" class="switch_option capsule_btn">
                <h5>Social<span>Donec vehicula, ex at molestie auctor, tortor eros commodo ex, vitae tempus enim diam non turpis.</span></h5>
                <input type="checkbox" v-model="social" id="social" hidden>
                <div><span></span></div>
            </label>
            <div v-if="social" class="edit_section">
                <label for="showfacebook" class="switch_option capsule_btn">
                    <h5>Show Facebook<span>Donec vehicula, ex at molestie auctor, tortor eros commodo ex, vitae tempus enim diam non turpis.</span></h5>
                    <input type="checkbox" v-model="showFacebook" id="showfacebook" hidden>
                    <div><span></span></div>
                </label>
                <div v-if="showFacebook">
                    <div class="form_grp">
                        <div class="group_item">
                            <div class="field_wpr">
                                <input type="text" placeholder="https://www.facebook.com/onboardme" >
                            </div>
                        </div>
                    </div>
                    <label for="fbicon" class="switch_option capsule_btn">
                        <h5>Use Default Facebook Icon<span>Donec vehicula, ex at molestie auctor, tortor eros commodo ex, vitae tempus enim diam non turpis.</span></h5>
                        <input type="checkbox" v-model="fbicon" id="fbicon" hidden>
                        <div><span></span></div>
                    </label>
                    <div v-if="!fbicon" class="upload_image">
                        <label for="logo">                   
                            <input type="file" @change="fileSelected" id="logo" hidden>
                            <img src="../assets/images/image2.png">
                            <h4>Click to Upload</h4>
                            <p>or drag and drop SVG, PNG, JPG or GIF</p>
                        </label>
                    </div>
                </div>
                <label for="showinstagram" class="switch_option capsule_btn">
                    <h5>Show Instagram<span>Donec vehicula, ex at molestie auctor, tortor eros commodo ex, vitae tempus enim diam non turpis.</span></h5>
                    <input type="checkbox" v-model="showInstagram" id="showinstagram" hidden>
                    <div><span></span></div>
                </label>
                <div v-if="showInstagram">
                    <div class="form_grp">
                        <div class="group_item">
                            <div class="field_wpr">
                                <input type="text" placeholder="https://www.instagram.com/onboardme" >
                            </div>
                        </div>
                    </div>
                    <label for="instaicon" class="switch_option capsule_btn">
                        <h5>Use Default Instagram Icon<span>Donec vehicula, ex at molestie auctor, tortor eros commodo ex, vitae tempus enim diam non turpis.</span></h5>
                        <input type="checkbox" v-model="instaicon" id="instaicon" hidden>
                        <div><span></span></div>
                    </label>
                    <div v-if="!instaicon" class="upload_image">
                        <label for="logo">                   
                            <input type="file" @change="fileSelected" id="logo" hidden>
                            <img src="../assets/images/image2.png">
                            <h4>Click to Upload</h4>
                            <p>or drag and drop SVG, PNG, JPG or GIF</p>
                        </label>
                    </div>
                </div>
                <label for="showyoutube" class="switch_option capsule_btn">
                    <h5>Show Youtube<span>Donec vehicula, ex at molestie auctor, tortor eros commodo ex, vitae tempus enim diam non turpis.</span></h5>
                    <input type="checkbox" v-model="showYoutube" id="showyoutube" hidden>
                    <div><span></span></div>
                </label>
                <div v-if="showYoutube">
                    <div class="form_grp">
                        <div class="group_item">
                            <div class="field_wpr">
                                <input type="text" placeholder="https://www.youtube.com/onboardme" >
                            </div>
                        </div>
                    </div>
                    <label for="instaicon" class="switch_option capsule_btn">
                        <h5>Use Default Youtube Icon<span>Donec vehicula, ex at molestie auctor, tortor eros commodo ex, vitae tempus enim diam non turpis.</span></h5>
                        <input type="checkbox" v-model="youtubeicon" id="instaicon" hidden>
                        <div><span></span></div>
                    </label>
                    <div v-if="!youtubeicon" class="upload_image">
                        <label for="logo">                   
                            <input type="file" @change="fileSelected" id="logo" hidden>
                            <img src="../assets/images/image2.png">
                            <h4>Click to Upload</h4>
                            <p>or drag and drop SVG, PNG, JPG or GIF</p>
                        </label>
                    </div>
                </div>
                <label for="showlinkedin" class="switch_option capsule_btn">
                    <h5>Show Linkedin<span>Donec vehicula, ex at molestie auctor, tortor eros commodo ex, vitae tempus enim diam non turpis.</span></h5>
                    <input type="checkbox" v-model="showLinkedin" id="showlinkedin" hidden>
                    <div><span></span></div>
                </label>
                <div v-if="showLinkedin">
                    <div class="form_grp">
                        <div class="group_item">
                            <div class="field_wpr">
                                <input type="text" placeholder="https://www.linkedin.com/onboardme" >
                            </div>
                        </div>
                    </div>
                    <label for="linkedinicon" class="switch_option capsule_btn">
                        <h5>Use Default Linkedin Icon<span>Donec vehicula, ex at molestie auctor, tortor eros commodo ex, vitae tempus enim diam non turpis.</span></h5>
                        <input type="checkbox" v-model="linkedinicon" id="linkedinicon" hidden>
                        <div><span></span></div>
                    </label>
                    <div v-if="!linkedinicon" class="upload_image">
                        <label for="logo">                   
                            <input type="file" @change="fileSelected" id="logo" hidden>
                            <img src="../assets/images/image2.png">
                            <h4>Click to Upload</h4>
                            <p>or drag and drop SVG, PNG, JPG or GIF</p>
                        </label>
                    </div>
                </div>
            </div>
            <label for="usedefault" class="switch_option capsule_btn">
                <h5>Use as Default<span>Donec vehicula, ex at molestie auctor, tortor eros commodo ex, vitae tempus enim diam non turpis.</span></h5>
                <input type="checkbox" v-model="useDefault" id="usedefault" hidden>
                <div><span></span></div>
            </label>
            <label v-if="useDefault" for="defaultsig" class="switch_option capsule_btn">
                <h5>Show default signature on all emails<span>Donec vehicula, ex at molestie auctor, tortor eros commodo ex, vitae tempus enim diam non turpis.</span></h5>
                <input type="checkbox" v-model="defaultSig" id="defaultsig" hidden>
                <div><span></span></div>
            </label>
        </div>
    </div>
</template>

<script>
import Multiselect from '@vueform/multiselect';
import EmojiPicker from '@/components/EmojiPicker';
export default {
    name: 'EmailComp',
    props:{
        isRecipient: Boolean,
    },
    components:{
        Multiselect,
        EmojiPicker
    },
    data(){
        return{
            emailAdd: false,
            emailOption: false,
            subject: '',
            msgOption: false,
            emailLibrary: false,
            testEmail: false,
            templateType: '',
            createSignature: false,
            showEmail: '',
            showSignature: '',
            brandBar: '',
            bio: '',
            abbreviate: '',
            social: '',
            useDefault: '',
            defaultSig: '',
            showFacebook: '',
            fbicon: true,
            showInstagram: '',
            instaicon: true,
            showYoutube: '',
            youtubeicon: true,
            showLinkedin: '',
            linkedinicon: true,  
            recipients:{
                mode: 'tags',
                value: [],
                options: [
                    { value: 'Recipient 1', label: 'Recipient 1' },
                    { value: 'Recipient 2', label: 'Recipient 2' },
                ],
                searchable: true,
                createTag: true
            },
        }
    },
    methods:{
            setEmoji (emoji) {
                this.subject = emoji;
            },
    }
}
</script>

<style scoped>
    :deep(.v3-input-emoji-picker) {
        margin: 0 0 0 auto;
    }

    :deep(.subject-emoji-picker .custom-emoji-picker) {
        top: 0;
    }

    :deep(.v3-input-picker-icon) {
        height: 0;
        margin-right: 5px;
    }
</style>